<svelte:options tag="fds-dialog" />

<script>
    import { onMount, beforeUpdate, createEventDispatcher } from "svelte";
    import { fade, fly } from "svelte/transition";
    import { quintOut } from "svelte/easing";
    import { stylestr } from "./styles";
    import { get_current_component } from "svelte/internal";
    import fdsHelper from "@fds-components/fds-helper";
    import { name, version as componentversion } from "../package.json";

    let host = get_current_component();
    let dialogelement;
    let svelteDispatch = createEventDispatcher();
    const dispatch = (name, detail) => {
        svelteDispatch(name, detail);
        host.dispatchEvent &&
            host.dispatchEvent(new CustomEvent(name, { detail }));
    };

    const noop = () => {};
    /**
     * set to true to open dialog or to false to close it
     * @type {boolean}
     */
    export let open = false;

    /**
     * Additionaly clases for dialog
     * @type {string}
     */
    export let dialogClasses = "";
    /**
     * if background around dialog should be visible
     * @type {boolean}
     */
    export let backdrop = true;
    /**
     * if clicking outside dialog close the dialog
     * @type {boolean}
     */
    export let ignorebackdrop = true;
    /**
     * activate keys to control dialog
     * @type {boolean}
     */
    export let keyboard = false;
    /**
     * is used to indicate the IDs of the elements that describe the object
     * @type {string}
     */
    export let describedby = "";
    /**
     * attribute establishes relationships between objects and their label(s)
     * @type {string}
     */
    export let labelledby = "";
    /**
     * callback run on dialog open
     * @type {function}
     */
    export let onOpened = noop;
    /**
     * strignified callback run on dialog open
     * @type {string | function}
     */
    export let onopened = "";
    
    /**
     * callback run on dialog close
     * @type {function}
     */
    export let onClosed = noop;
    /**
     * strignified callback run on dialog close
     * @type {string|function}
     */
    export let onclosed = "";
    /**
     * Determine if dialog has title element with close button
     * @type {boolean}
     */
    export let hasheader = true;

    /**
     * callback when dialog is displayed
     * @type {function}
     */
    export let onShown = noop;
    /**
     * strignified callback when dialog is displayed
     * @type {string|function}
     */
    export let onshown = "";

    /**
     * html or text content of dialog
     * @type {string|html}
     */
    export let modalcontent = "";
    /**
     * dom element of content element, you can addchild to it instead of set modalcontent
     * @type {HTMLElement}
     */
    export let contentelement;
    /**
     * dom element of title element, you can addchild to it instead of set modaltitle
     * @type {HTMLElement}
     */
    export let titleelement;
    /**
     * dom element of footer element, you can addchild to it instead of set modalfooter
     * @type {HTMLElement}
     */
    export let footerelement;
    /**
     * content of footer of dialog
     * @type {string}
     */
    export let modalfooter = "";
    /**
     * content of title of dialog
     * @type {string}
     */
    export let modaltitle = "";
    /**
     * size(type) of dialog
     * @type {xs,sm,lg,xl}
     */
    export let size = "";
    /**
     * width of dialog
     * @type {string}
     */
    export let width = "600px";
    /**
     * move fds-dialog to body, to avoid event stealing from parent component
     * @type {boolean}
     */
    export let movetobody = false;

    /**
     * move fds-dialog to body, to avoid event stealing from parent component
     * @type {string}
     */
    export let design = null;

    const component = get_current_component();
    let fontsize = "16px";
    let state = null;
    let _keyboardEvent;
    let stylestring = stylestr;
    let styleel = null;
    let fullsizeClass;
    let path = fdsHelper.get_href();
    let initialized = false;
    let movedtobody;
    $: {
        if (movetobody == "false") movetobody = false;
        movetobody = !!movetobody;
    }
    // Watching changes for Open vairable
    $: {
        if (open && document.body) {
            modalOpen();
        } else if (document.body) {
            modalClose();
        }
    }

    $: {
        if (size === "xs") width = "150px";
        if (size === "sm") width = "300px";
        if (size === "") width = "600px";
        if (size === "lg") width = "900px";
        if (size === "xl") width = "1200px";
        if (size === "xxl") width = "1600px";
        fullsizeClass = "";

        if (size === "fullsize") {
            fullsizeClass = "fullsize";
        }
    }

    $: if (open && initialized && !movedtobody) {
        if (
            movetobody &&
            component &&
            component.parentNode &&
            component.parentNode !== document.body
        ) {
            movedtobody = true;
            document.body.appendChild(component);
        }
    }

    /**
     * Get information about component
     * @param  {("api" | "examples" | "css")} type the info type
     */
    export async function getInfo(type) {
        if (type === "version") {
            return new Promise((resolve) => {
                resolve(componentversion);
            });
        }
        let res = await fdsHelper.getInfo(type, name);
        return res;
    }

    /**
     * version of component
     * @type {string}
     */
    export const version = componentversion;

    /**
     * Show dialog with properties object
     * @param configobj
     */

    export function show(configobj) {
        configObjtoLocalVars(configobj);
        open = true;
    }

    /**
     * close dialog
     */
    export function close() {
        open = false;
    }

    function configObjtoLocalVars(configobj) {
        if ("open" in configobj) open = configobj.open;
        if ("dialogClasses" in configobj)
            dialogClasses = configobj.dialogClasses;
        if ("ignorebackdrop" in configobj)
            ignorebackdrop = configobj.ignorebackdrop;
        if ("onOpened" in configobj) onOpened = configobj.onOpened;
        if ("onClosed" in configobj) onClosed = configobj.onClosed;
        if ("onshown" in configobj) onshown = configobj.onshown;
        if ("modalcontent" in configobj) modalcontent = configobj.modalcontent;
        if ("modalfooter" in configobj) modalfooter = configobj.modalfooter;
        if ("modaltitle" in configobj) modaltitle = configobj.modaltitle;
    }

    function setStateFromLocal() {
        state = {
            open,
            dialogClasses,
            backdrop,
            ignorebackdrop,
            keyboard,
            describedby,
            labelledby,
            onOpened,
            onShown,
            onClosed,
            modalcontent,
            modalfooter,
            modaltitle,
        };
    }

    beforeUpdate(() => {
        if (styleel && stylestring) {
            styleel.innerHTML = stylestring;

            if (design && design == "clear") {
                styleel.innerHTML +=
                    " .modal-content { border-radius: 0 !important; border: none; box-shadow: none; background-color: transparent}";
                styleel.innerHTML += " .close { color: white; opacity: 1;}";
                styleel.innerHTML +=
                    " .cross { font-family: auto; font-size: 32px; }";
                styleel.innerHTML +=
                    " .modal-body {  padding: 0; max-height: none}";
            }
        }
    });

    onMount(() => {
        initialized = true;
        setStateFromLocal();
    });

    function attachEvent(target, ...args) {
        target.addEventListener(...args);
        return {
            remove: () => target.removeEventListener(...args),
        };
    }

    function checkClass(className) {
        if (!className) return;
        return document.body.classList.contains(className);
    }

    function modalOpen() {
        if (!checkClass("modal-open")) {
            document.body.classList.add("modal-open");
        }
    }
    function modalClose() {
        if (checkClass("modal-open")) {
            document.body.classList.remove("modal-open");
        }
    }

    function handleBackdrop(event) {
        if (backdrop && !ignorebackdrop) {
            event.stopPropagation();
            open = false;
        }
    }

    /**
     * Will be fired if  dialog is open
     * @event open
     */
    /**
     * Will be fired if shown
     * @event show
     */

    function onModalOpened() {
        if (keyboard) {
            _keyboardEvent = attachEvent(document, "keydown", (e) => {
                if (event.key === "Escape") {
                    open = false;
                }
            });
        }

        if (
            (typeof onopened === "string" || onopened instanceof String) &&
            onopened
        ) {
            eval(onopened)();
        }

        if (typeof onopened === "function") {
            onopened(component);
        }
        onOpened(component);
        dispatch("open", {});

        if (
            (typeof onshown === "string" || onshown instanceof String) &&
            onshown
        ) {
            eval(onshown)();
        }
        if (typeof onshown === "function") {
            onshown(component);
        }
        onShown(component);
        dispatch("show", {});
    }

    /**
     * Will be fired if modal is closed
     * @event close
     */
    function onModalClosed() {
        if (_keyboardEvent) {
            _keyboardEvent.remove();
        }
        if (
            (typeof onclosed === "string" || onclosed instanceof String) &&
            onclosed
        ) {
            eval(onclosed)();
        }
        if (typeof onclosed === "function") {
            onclosed(component);
        }
        onClosed();
        dispatch("close", {});
    }

    function fixfade(node, params) {
        Object.defineProperty(node, "ownerDocument", {
            get: function () {
                return { head: node.parentNode };
            },
        });
        return fade(node, params);
    }
</script>

{#if open && initialized}
    <div
        bind:this={dialogelement}
        style="--width: {width};"
        class="modal show {fullsizeClass}"
        tabindex="-1"
        role="dialog"
        aria-labelledby={labelledby}
        aria-describedby={describedby}
        aria-modal="true"
        on:click|self={handleBackdrop}
        on:introend={onModalOpened}
        on:outroend={onModalClosed}
        transition:fixfade
        >
        <div
            style=""
            class="modal-dialog {dialogClasses}"
            role="document"
            in:fly={{ y: -50, duration: 300 }}
            out:fly={{ y: -50, duration: 300, easing: quintOut }}
        >
            <div class="modal-content">
                {#if !design && design != "clear"}
                    {#if hasheader}
                        <div class="modal-header">
                            <h5 bind:this={titleelement} class="modal-title">
                                <slot name="modaltitle">{@html modaltitle}</slot>
                            </h5>
                            <button
                                type="button"
                                class="close"
                                on:click={() => (open = false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    {/if}
                {:else}
                    <button
                        type="button"
                        class="close"
                        on:click={() => (open = false)}
                    >
                        <span class="cross" aria-hidden="true">&times;</span>
                    </button>
                {/if}
                <div bind:this={contentelement} class="modal-body">
                    <slot name="modalcontent">{@html modalcontent}</slot>
                </div>
                {#if modalfooter}
                    <div bind:this={footerelement} class="modal-footer">
                        <slot name="modalfooter">{@html modalfooter}</slot>
                    </div>
                {/if}
            </div>
        </div>
    </div>
    {#if open}
        <div
            class="modal-backdrop show"
            transition:fixfade={{ duration: 150 }}
        />
    {/if}
{/if}

{#if stylestring}
    <style bind:this={styleel}></style>
{/if}
